$(document).ready(function() {
  $('body').on('click', '.btn-submit', function(){
    $('.alert-alert').addClass('hide')
  });
  $('.login #new_user').validate({
    errorPlacement: function (error, element) {
      error.addClass('red-text')
      error.insertAfter(element);
    },
    rules: {
      'user[email]': {
       required: true,
       email: true
      },
      'user[password]': {
        required: true,
      }
    },
    highlight: function(element) {
      $(element).addClass('border-red');
    },
    unhighlight: function(element) {
      $(element).removeClass('border-red');
    },
    messages:{
      'user[email]':{
        required: 'メールを入力してください。',
        email: 'メールは不正な値です。',
      },
      'user[password]': {
        required: 'パスワードを入力してください。'
      }
    }
  });
  $('.alert-timedout').addClass('hide');
});
